import * as React from 'react';
import { hideOthers } from 'aria-hidden';
import { InteractivityDisabler } from './InteractivityDisabler';
import { focusHiddenMarker } from './medium';
import { useEffect, useRef, useState } from 'react';
var extractRef = function (ref) {
    return 'current' in ref ? ref.current : ref;
};
export function Effect(_a) {
    var setLockProps = _a.setLockProps, onEscapeKey = _a.onEscapeKey, onClickOutside = _a.onClickOutside, shards = _a.shards, onActivation = _a.onActivation, onDeactivation = _a.onDeactivation, noIsolation = _a.noIsolation;
    var _b = useState(undefined), activeNode = _b[0], setActiveNode = _b[1];
    var lastEventTarget = useRef(null);
    var mouseTouches = useRef(0);
    React.useEffect(function () {
        var onKeyDown = function (event) {
            if (!event.defaultPrevented) {
                if ((event.code === 'Escape' ||
                    event.key === 'Escape' ||
                    event.keyCode === 27) &&
                    onEscapeKey) {
                    onEscapeKey(event);
                }
            }
        };
        var onMouseDown = function (event) {
            if (event.defaultPrevented ||
                event.target === lastEventTarget.current ||
                (event instanceof MouseEvent && event.button !== 0)) {
                return;
            }
            if (shards &&
                shards
                    .map(extractRef)
                    .some(function (node) {
                    return (node && node.contains(event.target)) ||
                        node === event.target;
                })) {
                return;
            }
            if (onClickOutside) {
                onClickOutside(event);
            }
        };
        var onTouchStart = function (event) {
            onMouseDown(event);
            mouseTouches.current = event.touches.length;
        };
        var onTouchEnd = function (event) {
            mouseTouches.current = event.touches.length;
        };
        if (activeNode) {
            document.addEventListener('keydown', onKeyDown);
            document.addEventListener('mousedown', onMouseDown);
            document.addEventListener('touchstart', onTouchStart);
            document.addEventListener('touchend', onTouchEnd);
            return function () {
                document.removeEventListener('keydown', onKeyDown);
                document.removeEventListener('mousedown', onMouseDown);
                document.removeEventListener('touchstart', onTouchStart);
                document.removeEventListener('touchend', onTouchEnd);
            };
        }
    }, [activeNode, onClickOutside, onEscapeKey]);
    useEffect(function () {
        if (activeNode) {
            if (onActivation) {
                onActivation(activeNode);
            }
            return function () {
                if (onDeactivation) {
                    onDeactivation();
                }
            };
        }
    }, [!!activeNode]);
    useEffect(function () {
        var _undo = function () { return null; };
        var unmounted = false;
        var onNodeActivation = function (node) {
            _undo = hideOthers([node].concat((shards || []).map(extractRef)), document.body, noIsolation ? undefined : focusHiddenMarker);
            setActiveNode(function () { return node; });
        };
        var onNodeDeactivation = function () {
            _undo();
            if (!unmounted) {
                setActiveNode(null);
            }
        };
        setLockProps({
            onMouseDown: function (e) {
                lastEventTarget.current = e.target;
            },
            onTouchStart: function (e) {
                lastEventTarget.current = e.target;
            },
            onActivation: onNodeActivation,
            onDeactivation: onNodeDeactivation
        });
        return function () {
            unmounted = true;
            setLockProps(false);
        };
    }, []);
    return React.createElement(InteractivityDisabler, null);
}
