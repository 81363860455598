import * as tslib_1 from "tslib";
import * as React from 'react';
import { RemoveScroll } from 'react-remove-scroll/UI';
import ReactFocusLock from 'react-focus-lock/UI';
import { effectCar } from './medium';
export var FocusOn = React.forwardRef(function (props, parentRef) {
    var _a = React.useState(false), lockProps = _a[0], setLockProps = _a[1];
    var children = props.children, autoFocus = props.autoFocus, shards = props.shards, _b = props.enabled, enabled = _b === void 0 ? true : _b, _c = props.scrollLock, scrollLock = _c === void 0 ? true : _c, _d = props.focusLock, focusLock = _d === void 0 ? true : _d, _e = props.returnFocus, returnFocus = _e === void 0 ? true : _e, inert = props.inert, allowPinchZoom = props.allowPinchZoom, sideCar = props.sideCar, className = props.className, shouldIgnore = props.shouldIgnore, style = props.style, as = props.as, rest = tslib_1.__rest(props, ["children", "autoFocus", "shards", "enabled", "scrollLock", "focusLock", "returnFocus", "inert", "allowPinchZoom", "sideCar", "className", "shouldIgnore", "style", "as"]);
    var SideCar = sideCar;
    var onActivation = lockProps.onActivation, onDeactivation = lockProps.onDeactivation, restProps = tslib_1.__rest(lockProps, ["onActivation", "onDeactivation"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactFocusLock, { ref: parentRef, sideCar: sideCar, disabled: !(lockProps && enabled && focusLock), returnFocus: returnFocus, autoFocus: autoFocus, shards: shards, onActivation: onActivation, onDeactivation: onDeactivation, className: className, as: RemoveScroll, whiteList: shouldIgnore, lockProps: tslib_1.__assign({}, restProps, { sideCar: sideCar,
                shards: shards,
                allowPinchZoom: allowPinchZoom,
                as: as,
                inert: inert,
                style: style, enabled: enabled && scrollLock }) }, children),
        enabled && (React.createElement(SideCar, tslib_1.__assign({}, rest, { sideCar: effectCar, setLockProps: setLockProps, shards: shards })))));
});
export * from './reExports';
